import Keycloak from 'keycloak-js';

let keycloakInstance = null;
let customerNameValue = null;

export const createKeycloakInstance = (realm, clientId,customerName) => {

  customerNameValue = customerName;

  keycloakInstance = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm,
    clientId,
  });

  keycloakInstance.customerName = customerName;

  localStorage.setItem('realm', realm);
  localStorage.setItem('clientId', clientId);
  localStorage.setItem('customerName', customerName);

  return keycloakInstance;
};

export const getKeycloakInstance = () => keycloakInstance;

export const getCustomerName = () => customerNameValue || localStorage.getItem('customerName');

// export const getKeycloakLogoutUrl = () => {

//   if (!keycloakInstance) {
//     console.error('Keycloak instance not initialized');
//     return null;
//   }

//   // const baseUrl = process.env.REACT_APP_KEYCLOAK_URL;
//   // const realm = keycloakInstance.realm;
//     return `https://${keycloakInstance.customerName}.mg.invinsense.io`  
//   // return `${baseUrl}/realms/${realm}/protocol/openid-connect/logout`;
// };

export const getKeycloakLogoutUrl = () => {
  if (!keycloakInstance) {
    console.error('Keycloak instance not initialized');
    return null;
  }

  const customerName = getCustomerName();
  if (!customerName) {
    console.error('Customer name not found');
    return null;
  }

  return `https://${customerName}.mg.invinsense.io`;
};